// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import { Modal, Carousel, Tab } from 'bootstrap'

import './components/animations'
import './components/carousel'
import './components/ga_events'
import './components/link'
import './components/modal'
import './components/navbar'
import './components/show-more'

// Allow iFrame resizing
window.resizeIframe = obj => {
  setTimeout(
    () => {
      // eslint-disable-next-line no-param-reassign
      obj.style.height = `${obj.contentWindow.document.documentElement
        .scrollHeight + 30}px`
    },

    2000 // Wait for Translation and Rates to be loaded before resizing
  )
}
